#slider {
  width: 300px;
  text-align: center;

  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;

  text-shadow: 0 0 3px black;
}
#slider > div {
  flex-shrink: 0;
  width: 300px !important;
  height: 300px !important;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  scroll-margin-top: 12rem;
}
#slider > div:target {
  transform: scale(0.8);
}

#slider::-webkit-scrollbar {
  display: none;
}
