body {
  --primary:#4355b9;
  --on-primary:#ffffff;
  --primary-container:#dee0ff;
  --on-primary-container:#00105c;
  --secondary:#5b5d72;
  --on-secondary:#ffffff;
  --secondary-container:#e0e1f9;
  --on-secondary-container:#181a2c;
  --tertiary:#77536d;
  --on-tertiary:#ffffff;
  --tertiary-container:#ffd7f1;
  --on-tertiary-container:#2d1228;
  --error:#ba1a1a;
  --on-error:#ffffff;
  --error-container:#ffdad6;
  --on-error-container:#410002;
  --background:#fefbff;
  --on-background:#1b1b1f;
  --surface:#fbf8fd;
  --on-surface:#1b1b1f;
  --surface-variant:#e3e1ec;
  --on-surface-variant:#46464f;
  --outline:#767680;
  --outline-variant:#c7c5d0;
  --shadow:#000000;
  --scrim:#000000;
  --inverse-surface:#303034;
  --inverse-on-surface:#f3f0f4;
  --inverse-primary:#bac3ff;
  --surface-dim:#dcd9de;
  --surface-bright:#fbf8fd;
  --surface-container-lowest:#ffffff;
  --surface-container-low:#f6f2f7;
  --surface-container:#f0edf1;
  --surface-container-high:#eae7ec;
  --surface-container-highest:#e4e1e6;
}

body.dark {
  --primary:#bac3ff;
  --on-primary:#08218a;
  --primary-container:#293ca0;
  --on-primary-container:#dee0ff;
  --secondary:#c3c5dd;
  --on-secondary:#2d2f42;
  --secondary-container:#434659;
  --on-secondary-container:#e0e1f9;
  --tertiary:#e6bad7;
  --on-tertiary:#44263d;
  --tertiary-container:#5d3c55;
  --on-tertiary-container:#ffd7f1;
  --error:#ffb4ab;
  --on-error:#690005;
  --error-container:#93000a;
  --on-error-container:#ffb4ab;
  --background:#1b1b1f;
  --on-background:#e4e1e6;
  --surface:#131316;
  --on-surface:#e4e1e6;
  --surface-variant:#46464f;
  --on-surface-variant:#c7c5d0;
  --outline:#90909a;
  --outline-variant:#46464f;
  --shadow:#000000;
  --scrim:#000000;
  --inverse-surface:#e4e1e6;
  --inverse-on-surface:#303034;
  --inverse-primary:#4355b9;
  --surface-dim:#131316;
  --surface-bright:#39393c;
  --surface-container-lowest:#0e0e11;
  --surface-container-low:#1b1b1f;
  --surface-container:#1f1f23;
  --surface-container-high:#2a2a2d;
  --surface-container-highest:#353438;
}
